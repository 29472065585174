<template>
  <div class="admin-results-page">
    <Toolbar>
      <template #left>
        <div class="p-fluid p-grid p-formgrid">
          <div class="p-field">
            <label for="start">Start</label>
            <Calendar id="start" v-model="date1" />
          </div>
          <div class="p-field">
            <label for="end">End</label>
            <Calendar id="end" v-model="date2" />
          </div>
          <div class="p-field">
            <label for="location">Location</label>
            <Dropdown
              id="location"
              v-model="selectedLocation"
              :options="familyLocations"
              optionLabel="name"
              placeholder="Select a Location"
            />
          </div>
          <div class="p-field">
            <label for="age-group">Age Group</label>
            <Dropdown
              id="age-group"
              v-model="selectedAgeGroup"
              :options="ageGroups"
              optionLabel="name"
              placeholder="Select a Age Group"
            />
          </div>
          <div class="p-field">
            <label for="gender">Gender</label>
            <Dropdown
              id="gender"
              v-model="selectedGender"
              :options="genders"
              optionLabel="name"
              placeholder="Select a Gender"
            />
          </div>
        </div>
      </template>

      <template #right>
        <Button icon="pi pi-search" class="p-mr-2" @click="loadStats" />
      </template>
    </Toolbar>
    <div class="p-grid p-nogutter">
      <div class="p-col p-p-2">
        <Card class="p-p-3">
          <template #content>
            <h1 class="trbc-color">Total Entries</h1>
            <h3 v-if="stats">{{ stats.totalEntries }}</h3>
          </template>
        </Card>
      </div>
      <div class="p-col p-p-2">
        <Card class="p-p-3">
          <template #content>
            <h1 class="trbc-color">Total Average Score</h1>
            <h3 v-if="stats">{{ stats.averageScore }}</h3>
          </template>
        </Card>
      </div>
    </div>
    <div v-if="stats" class="p-grid p-nogutter">
      <div v-for="(item, key) in stats.parts" :key="key" class="p-col p-p-2">
        <Card class="p-p-3">
          <template #title>
            <div class="trbc-color">{{ key }}</div>
          </template>
          <template #content>
            <h4>Average: {{ item.averageScore }}</h4>
            <Card
              class="p-p-2 p-my-2"
              v-for="(message, index) in item.messages"
              :key="`${key}-msg-${index}`"
            >
              <template #content>
                <h5 class="trbc-color">{{ message.text }}</h5>
                <h6>Average: {{ message.averageScore }}</h6>
              </template>
            </Card>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import SurveyService from '@/services/SurveyService'

export default {
  name: 'AdminResults',
  setup() {
    const i18n = useI18n()

    const today = new Date()
    const date1 = ref(today)
    const date2 = ref(today)
    const stats = ref(null)

    const familyLocations = [
      {
        key: null,
        name: 'All'
      },
      {
        key: 'L',
        name: i18n.t('localFamily')
      },
      {
        key: 'N',
        name: i18n.t('nonLocalFamily')
      }
    ]

    const selectedLocation = ref(familyLocations[0])

    const ageGroups = [
      {
        key: null,
        name: 'All'
      },
      {
        key: '0-12',
        name: '12 & under'
      },
      {
        key: '13-17',
        name: '13-17'
      },
      {
        key: '18-25',
        name: '18-25'
      },
      {
        key: '26-35',
        name: '26-35'
      },
      {
        key: '36-45',
        name: '36-45'
      },
      {
        key: '46-55',
        name: '46-55'
      },
      {
        key: '56-65',
        name: '56-65'
      },
      {
        key: '65plus',
        name: '65+'
      }
    ]

    const selectedAgeGroup = ref(ageGroups[0])

    const genders = [
      {
        key: null,
        name: 'All'
      },
      {
        key: 'male',
        name: 'Male'
      },
      {
        key: 'female',
        name: 'Female'
      }
    ]

    const selectedGender = ref(genders[0])

    const loadStats = function () {
      SurveyService.getStats(
        date1.value,
        date2.value,
        selectedLocation.value.key,
        selectedAgeGroup.value.key,
        selectedGender.value.key
      ).then((data) => {
        stats.value = data
      })
    }

    onMounted(() => {
      loadStats()
    })

    return {
      date1,
      date2,
      stats,
      loadStats,
      familyLocations,
      ageGroups,
      genders,
      selectedLocation,
      selectedAgeGroup,
      selectedGender
    }
  }
}
</script>

<style scoped>
.admin-results-page {
  padding: 24px;
}
</style>
